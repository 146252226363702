<template>
  <form-slot-layout :title="`Google Analytics`">
    <validation-provider
      #default="{ errors }"
      :name="`Google Analytics`"
    >
      <b-form-input
        id="input-google-analytics"
        v-model="google"
        :placeholder="`Google Analytics`"
        size="lg"
        :disabled="readOnly"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </form-slot-layout>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    FormSlotLayout,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { google } = inject('apiKeys')
    return {
      google,
    }
  },
}
</script>
